import React, { useState } from "react";
import styled from "styled-components";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Wrapper from "../../elements/wrapper";
import { media } from "../../helpers";

import Slideshow from "./slideshow";

const BrandsContainer = styled.div`
  padding-top: 70px;

  .title-container {
    text-align: center;
    padding-bottom: 100px;
    h1 {
      font-weight: 900;
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      color: #222;
      font-size: 32px;
      ${media.medium`font-size: 42px;`}
    }
  }

  .all-logos-container {
    ${media.large`max-width: 1080px;`}
    .logo-section-container {
      padding-bottom: 70px;
      ${media.medium`padding-bottom: 100px;`}
      ${media.large`padding-bottom: 140px;`}
      .title {
        margin-bottom: 40px;
        h3 {
          font-weight: 900;
          margin: 0;
          text-transform: uppercase;
          color: #222;
          font-size: 21px;
          ${media.medium`font-size: 34px;`}
        }
      }

      .logo-grid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        ${media.medium`margin: 0 -20px;`}
        .icon-container {
          flex: 0 0 33.33%;
          margin-bottom: 20px;
          ${media.small`flex: 0 0 25%;`};
          ${media.medium`flex: 0 0 20%;`};
          ${media.large`flex: 0 0 16.666%;`};

          .image-container {
            max-width: 100px;
            margin: 0 auto;

            .gatsby-image-wrapper {
              max-height: 100px;

              img {
                object-fit: contain !important;
              }
            }

            button {
              background-color: #fff;
              border: none;
              padding: 0;

              > div {
              }
            }
          }
        }
      }
    }
  }
`;

const Brands = ({ data }) => {
  let page = data?.brands?.edges[0]?.node || {};
  let galleries = data?.galleries?.edges || [];
  let { pageTitle, logoSections } = page?.brands_acf || {};
  const [selectedGallery, setSelectedGallery] = useState([]);
  const [isSlideshowActive, setIsSlideshowActive] = useState(false);

  function toggleSlideshow(gallery) {
    setSelectedGallery(gallery);
    setIsSlideshowActive(true);
    // openLightbox();
  }

  const options = {
    thumbnails: {
      showThumbnails: false,
    },
  };

  return (
    <BrandsContainer>
      <Wrapper className="brands-outer-container">
        <div className="title-container">
          <h1>{pageTitle}</h1>
        </div>
        <Wrapper className="all-logos-container">
          {logoSections.map((section, sectionIndex) => {
            const { sectionTitle, sectionIcons } = section || {};

            return (
              <div
                className="logo-section-container"
                key={`Logo Section ${sectionIndex}`}
              >
                <div className="title">
                  <h3>{sectionTitle}</h3>
                </div>

                <div className="logo-grid">
                  {sectionIcons.map((icon, iconIndex) => {
                    let image = getImage(icon?.iconImage?.localFile);
                    let doesIconHaveLink = icon.brandUrl !== null;

                    if (doesIconHaveLink) {
                      return (
                        <div className="icon-container" key={iconIndex}>
                          <div className="image-container">
                            <a href={icon.brandUrl} target="_blank">
                              <GatsbyImage
                                image={image}
                                alt={`Logo ` + iconIndex}
                              />
                            </a>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="icon-container" key={iconIndex}>
                          <div className="image-container">
                            <GatsbyImage
                              image={image}
                              alt={`Logo ` + iconIndex}
                              objectFit="contain"
                            />
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            );
          })}
        </Wrapper>
      </Wrapper>

      {isSlideshowActive && (
        <Slideshow
          gallery={selectedGallery}
          setIsSlideshowActive={setIsSlideshowActive}
        />
      )}
    </BrandsContainer>
  );
};

export default Brands;

import React, { useContext } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Brands from "../components/page-components/brands";
import { WebsiteContext } from "../context/WebsiteContext";

const BrandsPageContainer = styled.div`
  padding-top: ${props => props.distanceFromTop + "px"};
`;

const BrandsPage = ({ data }) => {
  const { distanceFromTop } = useContext(WebsiteContext);
  return (
    <Layout>
      <SEO title="Our Brands" />
      <BrandsPageContainer distanceFromTop={distanceFromTop}>
        <Brands data={data} />
      </BrandsPageContainer>
    </Layout>
  );
};

export default BrandsPage;

export const query = graphql`
  query {
    galleries: allWpGallery {
      edges {
        node {
          id
          slug
          gallery_acf {
            gallery {
              galleryImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 90
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      width: 1000
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
    brands: allWpPage(filter: { title: { eq: "Our Brands" } }) {
      edges {
        node {
          id
          brands_acf {
            pageTitle
            logoSections {
              sectionTitle
              sectionIcons {
                iconImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        width: 300
                      )
                    }
                  }
                }
                brandUrl
              }
            }
          }
        }
      }
    }
  }
`;
